<template>
  <div class="text-center loader">
    <div style="position: relative; top: 40%; color:black !important">
      <div
        class="la-square-jelly-box la-2x icon-wrapper">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading6',
};
</script>

<style lang="scss" src="./loading6.scss" scoped></style>